<template>
  <van-dialog v-model="show" class-name="popup-container" :show-cancel-button="false" :show-confirm-button="false">
    <div v-if="title" class="title-item img-cover">
      <img src="../assets/images/pop-head-bg.png" />
      <div class="title">{{ title }}</div>
    </div>
    <div v-if="tips" class="tips-item">{{ tips }}</div>
    <div v-if="content" class="content-item"><div v-html="content"></div></div>
    <div class="btn-item">
      <div v-if="showSure" class="btn sure" @click="sure">{{ sureText }}</div>
      <div v-if="showCancel" class="btn cancel" @click="cancel">{{ cancelText }}</div>
    </div>
  </van-dialog>
</template>

<script>
export default {
  emits: ['onSure', 'onCancel'],
  name: "MobilePopupComponent",
  data () {
    return {
      show: false,
      title: '',
      tips: '',
      content: '',
      showCancel: true,
      showSure: true,
      sureText: '确定',
      cancelText: '取消'
    }
  },
  methods: {
    open(data) {
      this.show = true
      this.title = data.title || ''
      this.tips = data.tips || ''
      this.content = data.content || ''
      this.showCancel = data.showCancel || false
      this.showConfirm = data.showConfirm || false
      if (data.sureText) {
        this.sureText = data.sureText
      }
      if (data.cancelText) {
        this.cancelText = data.cancelText
      }
    },
    sure() {
      this.$emit('onSure')
    },
    cancel() {
      this.$emit('onCancel')
    },
  }
}
</script>

<style lang="less" scoped>
.title-item {
  width: 100%;
  height: 1.2rem;
  position: relative;
  .title {
    position: absolute;
    top: .48rem;
    width: 100%;
    text-align: center;
    line-height: .48rem;
    color: #333;
    font-size: .32rem;
    font-weight: bold;
  }
}
.tips-item {
  padding: .08rem .32rem 0;
  text-align: center;
  font-size: .28rem;
  color: #ff2442;
}
.content-item {
  margin: .24rem .32rem;
  min-height: 1.84rem;
  overflow-y: auto;
  line-height: .48rem;
  color: #333;
  font-size: .28rem;
}
.btn-item {
  padding: 0 .32rem .32rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .btn {
    cursor: pointer;
    flex: 1;
    height: .8rem;
    color: #fff;
    text-align: center;
    line-height: .8rem;
    font-size: .28rem;
    font-weight: bold;
    margin-left: .24rem;
    border-radius: .12rem;
    &:nth-child(1) {
      margin-left: 0;
    }
    &.sure {
      background: linear-gradient( 135deg, #58b7ff 0%, #1399ff 100%);
    }
    &.cancel {
      background-color: #bac0cc;
    }
  }
}
</style>